<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3" lg="3" sm="12" xs="12" class="mb-3">
        <label class="lfont">ເດືອນ/ປີ</label>
        <DatePicker
          class="date-time"
          style="width: 100%"
          type="month"
          valueType="format"
          slot="activator"
          :append-to-body="true"
          name="founding_date"
          v-model="date"
          @change="fetchEmployeeMeetingHistory"
        ></DatePicker>
        <!-- <label class="label-input">{{ $t("ot_report.year") }}</label>
        <v-select :items="years" outlined dense v-model="year"></v-select> -->
      </v-col>
      <v-col cols="12">
        <div class="section-form-content">
          <div class="block-content">
            <table
              class="table table-view"
              v-if="listEmployeeMeetingHistory.length > 0"
            >
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("meeting.table.create") }}
                  </th>
                  <th class="text-left">
                    {{ $t("meeting.table.title") }}
                  </th>
                  <th class="text-left">
                    {{ $t("meeting.table.place") }}
                  </th>
                  <th class="text-left">
                    {{ $t("meeting.table.detail") }}
                  </th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in listEmployeeMeetingHistory"
                  :key="index"
                >
                  <td>{{ item.created_at }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.place }}</td>
                  <td>{{ item.detail }}</td>
                  <td>
                    <div>
                      <v-btn
                        color="secondary"
                        small
                        fab
                        dark
                        @click="modalShowDetail(item.id)"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <defaultTableNoResult v-else />
            <Loading v-if="isLoading" />
            <ShowModalDetail
              @success="fetchEmployeeMeetingHistory()"
              @close="dialog = false"
              :id="id"
              :dialog="dialog"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import ShowModalDetail from "./ShowDetail/ShowDetailHistory.vue";
import Loading from "@/components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    ShowModalDetail,

    Loading,
    Pagination,
    defaultTableNoResult,
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dialog: false,
      isLoading: false,
      id: "",
      listEmployeeMeetingHistory: [],
    };
  },
  methods: {
    onCreate() {
      this.$router
        .push({
          name: "employee_meeting.add",
        })
        .catch(() => {});
    },
    modalShowDetail(id) {
      this.dialog = true;
      this.id = id;
    },
    searchFilterItem() {
      this.fetchEmployeeMeetingHistory();
      // this.pagination.current_page = 1;
    },
    fetchEmployeeMeetingHistory() {
      this.isLoading = true;
      const year = moment(this.date).format("YYYY");
      const month = moment(this.date).format("MM");
      this.$axios
        .get(`/meeting/history`, {
          params: {
            year: year,
            month: month,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            // console.log("Meeting", res);
            this.isLoading = false;
            this.listEmployeeMeetingHistory = res.data.data;
            // this.pagination = res.data.data.pagination;
            // if (!this.listEmployeeMeetingHistory.length > 0) {
            //   this.pagination.current_page = this.pagination.current_page - 1;
            // }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeMeetingHistory();
  },
};
</script>

<style lang="scss" scoped>
.custom_table {
  margin-top: 10px;
  border: 1px solid rgb(131, 128, 128);
  width: auto;
  height: auto;
  padding: 5px;
}
</style>
